import { FC } from 'react';

export const Actions: FC = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.42264 7.00987C3.28087 7.00987 2.35529 7.96521 2.35529 9.14368V29.3658C2.35529 30.5442 3.28087 31.4996 4.42264 31.4996H24.0149C25.1567 31.4996 26.0823 30.5442 26.0823 29.3658V18.3304C26.0823 17.6958 26.5806 17.1814 27.1954 17.1814C27.8102 17.1814 28.3086 17.6958 28.3086 18.3304V29.3658C28.3086 31.8134 26.3863 33.7975 24.0149 33.7975H4.42264C2.05128 33.7975 0.128906 31.8134 0.128906 29.3658V9.14368C0.128906 6.69608 2.05128 4.71191 4.42264 4.71191H16.6481C17.2629 4.71191 17.7613 5.22633 17.7613 5.86089C17.7613 6.49545 17.2629 7.00987 16.6481 7.00987H4.42264Z"
        fill="#484848"
      />
      <path
        d="M26.669 2.59513C27.2962 1.94771 28.147 1.58398 29.0341 1.58398C29.9211 1.58398 30.7719 1.94771 31.3992 2.59513C32.0264 3.24256 32.3788 4.12066 32.3788 5.03626C32.3788 5.95186 32.0264 6.82996 31.3992 7.47739L16.4202 22.9379L10.1133 24.5653L11.69 18.0556L26.669 2.59513Z"
        fill="transparent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.034 2.73155C28.4421 2.73155 27.8745 2.97421 27.456 3.40617L12.6951 18.6416L11.6431 22.9848L15.851 21.899L30.6119 6.66353C31.0304 6.23157 31.2656 5.64572 31.2656 5.03485C31.2656 4.42397 31.0304 3.83812 30.6119 3.40617C30.1934 2.97421 29.6258 2.73155 29.034 2.73155ZM25.8817 1.78127C26.7178 0.918367 27.8517 0.433594 29.034 0.433594C30.2163 0.433594 31.3502 0.918367 32.1862 1.78127C33.0223 2.64417 33.4919 3.81452 33.4919 5.03485C33.4919 6.25517 33.0223 7.42552 32.1862 8.28842L17.2073 23.7489C17.0646 23.8962 16.8859 24.0006 16.6901 24.0511L10.3832 25.6785C10.0039 25.7764 9.60256 25.6617 9.32607 25.3763C9.04957 25.0909 8.93842 24.6767 9.03326 24.2852L10.61 17.7755C10.6589 17.5735 10.7601 17.389 10.9028 17.2417L25.8817 1.78127Z"
        fill="#484848"
      />
    </svg>
  );
};
