'use client';

import { FC, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonExtendedProps, Menu } from 'grommet';
import { StyledTopBarItem } from '@/lib/components/app/Styled';
import { Profile } from '@/lib/components/icons';

export const ProfileButton: FC = () => {
  const { user, loginWithRedirect, logout } = useAuth0();

  const items: ButtonExtendedProps[] = useMemo((): ButtonExtendedProps[] => {
    if (!user) {
      return [
        {
          label: 'Login',
          onClick: () => {
            void loginWithRedirect({
              openUrl: (url) => {
                const parsedUrl = new URL(url);
                parsedUrl.searchParams.append('app', 'uam');

                window.open(parsedUrl, '_self');
              },
              appState: {
                returnTo: '/my-fleet',
              },
            });
          },
        },
      ];
    }

    return [
      {
        label: user.email,
        style: {
          fontWeight: 'bold',
        },
        disabled: true,
      },
      {
        label: 'Sign out',
        onClick: () => {
          void logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        },
      },
    ];
  }, [user, loginWithRedirect, logout]);

  return (
    <Menu
      items={items}
      dropProps={{
        align: {
          top: 'bottom',
          left: 'left',
        },
      }}
      plain
    >
      <StyledTopBarItem>
        <Profile />
      </StyledTopBarItem>
    </Menu>
  );
};
