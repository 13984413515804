export const generalConfig = {
  domains: {
    root: process.env.NEXT_PUBLIC_ROOT_DOMAIN || undefined,
  },
  sentry: {
    enabled:
      process.env.SENTRY_ENABLED === 'true' ||
      process.env.NODE_ENV !== 'development',
  },
};

export type GeneralConfiguration = typeof generalConfig;
