import { auth0Config } from './auth0';
import { appsConfig } from './apps';
import { generalConfig } from './general';
import { graphqlConfig } from './graphql';
import { sentryConfig } from './sentry';

const config = {
  general: generalConfig,
  auth0: auth0Config,
  apps: appsConfig,
  graphql: graphqlConfig,
  sentry: sentryConfig,
};

export type Config = typeof config;

export default config;
