import { FC, ReactNode, useEffect, useState } from 'react';
import { createClient } from '@/lib/api/graphql/apollo';
import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

type ApolloProps = {
  children?: ReactNode;
};

export const Apollo: FC<ApolloProps> = ({ children }) => {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [client, setClient] = useState(createClient);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user) {
      return;
    }

    getAccessTokenSilently().then((token) => {
      setClient(createClient(token));
    });
  }, [isLoading, user, getAccessTokenSilently]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
