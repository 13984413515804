import { Box, Text } from 'grommet';
import { FC } from 'react';
import { ExternalApp, useExternalAppsQuery } from '@/lib/generated/graphql';
import Link from 'next/link';
import { buildAppUrl } from '@/lib/utils';
import styled from 'styled-components';

const PlainLink = styled(Link)(() => ({
  textDecoration: 'none',
  '&:visited': {
    color: 'unset',
  },
}));

type ExternalAppEdge = {
  node: ExternalApp;
  __typename: 'ExternalAppEdge';
};

export const ExternalAppsMenu: FC = () => {
  const { data } = useExternalAppsQuery({
    variables: {
      first: 100,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Box pad="small" overflow={{ vertical: 'auto' }} width={{ min: '200px' }}>
      <Text weight="bold">Applications</Text>

      <Box margin={{ top: 'small' }} direction="column" align="start">
        {data?.externalApps?.edges
          ?.filter(
            (edge): edge is ExternalAppEdge =>
              edge?.__typename === 'ExternalAppEdge',
          )
          ?.map?.((edge) => (
            <PlainLink
              key={edge.node.id}
              href={buildAppUrl('shell', 'apps/{id}/', {
                id: edge.node.id,
              })}
            >
              <Text weight="bold">{edge.node.name}</Text>
            </PlainLink>
          ))}
      </Box>
    </Box>
  );
};
