import { forwardRef, ReactNode } from 'react';
import {
  StyledLink,
  StyledSidebarItem,
  StyledSidebarItemLabel,
} from '../../Styled';
import { Box, BoxExtendedProps } from 'grommet';

type LabelProps = {
  icon: ReactNode;
  children: string;
  href?: string;
  boxProps?: Omit<BoxExtendedProps, 'ref'>;
  app?: string;
};

const Label = forwardRef<HTMLDivElement, LabelProps>(
  ({ icon, href, boxProps, app, children }, ref) => {
    const content = (
      <StyledSidebarItem
        {...boxProps}
        ref={ref}
        direction="row"
        wrap={false}
        align="center"
      >
        <Box>{icon}</Box>
        <StyledSidebarItemLabel>{children}</StyledSidebarItemLabel>
      </StyledSidebarItem>
    );

    if (!href) {
      return content;
    }
    return (
      <StyledLink href={href} data-app={app}>
        {content}
      </StyledLink>
    );
  },
);

Label.displayName = 'Label';

export { Label };
