import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const createCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          dataReports: relayStylePagination(['report']),
          dataReportSnapshots: relayStylePagination(['report']),
        },
      },
    },
  });
};
