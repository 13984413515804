import { FC } from 'react';

export const Dashboards: FC = () => {
  return (
    <svg
      width="32"
      height="38"
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.111328 15.2928C0.111328 14.1783 1.01481 13.2749 2.12931 13.2749H14.1133C15.2278 13.2749 16.1313 14.1783 16.1313 15.2928V35.1454C16.1313 36.2599 15.2278 37.1633 14.1133 37.1633H2.12931C1.01481 37.1633 0.111328 36.2599 0.111328 35.1454V15.2928ZM2.12931 15.2928V35.1454H14.1133V15.2928H2.12931Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3645 21.9955C34.3645 23.11 33.4611 24.0135 32.3466 24.0135H20.3626C19.2481 24.0135 18.3446 23.11 18.3446 21.9955V2.14299C18.3446 1.0285 19.2481 0.125016 20.3626 0.125016H32.3466C33.4611 0.125016 34.3645 1.0285 34.3645 2.14299V21.9955ZM32.3466 21.9955V2.14299H20.3626V21.9955H32.3466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.111328 2.14298C0.111328 1.02848 1.01481 0.125 2.12931 0.125H14.1133C15.2278 0.125 16.1313 1.02848 16.1313 2.14298V9.05899C16.1313 10.1735 15.2278 11.077 14.1133 11.077H2.12931C1.01481 11.077 0.111328 10.1735 0.111328 9.05899V2.14298ZM2.12931 2.14298V9.05899H14.1133V2.14298H2.12931Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3645 35.1454C34.3645 36.2599 33.4611 37.1633 32.3466 37.1633H20.3626C19.2481 37.1633 18.3446 36.2599 18.3446 35.1454V28.2293C18.3446 27.1148 19.2481 26.2114 20.3626 26.2114H32.3466C33.4611 26.2114 34.3645 27.1148 34.3645 28.2293V35.1454ZM32.3466 35.1454V28.2293H20.3626V35.1454H32.3466Z"
        fill="white"
      />
    </svg>
  );
};
