import { Apollo } from '@/lib/components/app/Apollo';
import { Auth } from '@/lib/components/app/Auth';
import config from '@/lib/config';
import { theme } from '@/lib/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { Roboto } from 'next/font/google';
import { Box, Grid, Grommet } from 'grommet';
import Router from 'next/router';
import { FC, ReactNode } from 'react';
import { Sidebar } from './Sidebar';
import { TopBar } from './TopBar';
import { PostMessageContextProvider } from '@/lib/contexts/PostMessageContext';

const robotoFont = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin'],
});

/**
 * Application wrapper
 * @constructor
 */
export const ApplicationWrapper: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  return (
    <>
      {' '}
      <style jsx global>{`
        html {
          font-family: ${robotoFont.style.fontFamily};
        }
      `}</style>
      <Auth0Provider
        {...config.auth0}
        cookieDomain={config.general.domains.root}
        cacheLocation="localstorage"
        useRefreshTokensFallback={false}
        onRedirectCallback={(state) => {
          void Router.replace(state?.returnTo || '/');
        }}
      >
        <PostMessageContextProvider>
          <Grommet theme={theme} themeMode="light" full>
            <Auth>
              <Apollo>
                <Grid
                  rows={['65px', 'flex']}
                  columns={['auto', 'flex']}
                  areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'nav', start: [0, 1], end: [0, 1] },
                    { name: 'main', start: [1, 1], end: [1, 1] },
                  ]}
                  style={{ minHeight: '100%' }}
                >
                  <TopBar gridArea="header" />
                  <Sidebar gridArea="nav" />
                  <Box gridArea="main" background={{ color: 'white' }}>
                    <Box fill style={{ position: 'relative' }}>
                      {children}
                    </Box>
                  </Box>
                </Grid>
              </Apollo>
            </Auth>
          </Grommet>
        </PostMessageContextProvider>
      </Auth0Provider>
    </>
  );
};
