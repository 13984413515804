import { FC } from 'react';

export const Search: FC = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7451 15.5302C16.1373 15.1381 16.7732 15.1381 17.1654 15.5302L22.5215 20.8864C22.9137 21.2786 22.9137 21.9145 22.5215 22.3067C22.1293 22.6989 21.4935 22.6989 21.1013 22.3067L15.7451 16.9505C15.3529 16.5583 15.3529 15.9224 15.7451 15.5302Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76001 16.9099C13.8274 16.9099 17.1247 13.6126 17.1247 9.54517C17.1247 5.47774 13.8274 2.18044 9.76001 2.18044C5.69258 2.18044 2.39528 5.47774 2.39528 9.54517C2.39528 13.6126 5.69258 16.9099 9.76001 16.9099ZM9.76001 18.9185C14.9367 18.9185 19.1333 14.7219 19.1333 9.54517C19.1333 4.36844 14.9367 0.171875 9.76001 0.171875C4.58329 0.171875 0.386719 4.36844 0.386719 9.54517C0.386719 14.7219 4.58329 18.9185 9.76001 18.9185Z"
        fill="#376E8D"
      />
    </svg>
  );
};
