import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import Config from '@/lib/config';
import { createCache } from './cache';

const GRAPHQL_SERVER_SIDE_API_URL =
  process.env.NEXT_PUBLIC_GRAPHQL_SERVER_SIDE_API_URL ||
  'http://backend:8000/graphql/';

export const createClient = (token?: string) => {
  const headers: Record<string, string> = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const link = new HttpLink({
    uri: Config.graphql.endpoint,
    credentials: 'include',
    headers,
  });

  const cache = createCache();

  return new ApolloClient({
    link,
    cache,
  });
};

export const serverClient = new ApolloClient({
  uri: GRAPHQL_SERVER_SIDE_API_URL,
  cache: new InMemoryCache(),
  credentials: 'include',
});
