import { init } from '@sentry/nextjs';

type Configuration = Parameters<typeof init>[0];

const env = {
  dsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://92273572d7164bcc882c199b97ccb936@o332776.ingest.sentry.io/4506157518749696',
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE
    ? Number(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE)
    : 1.0,
};

export const sentryConfig: Configuration = {
  dsn: env.dsn,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: env.tracesSampleRate,
};
