import { createContext, FC, ReactNode, useContext } from 'react';

type SidebarContextType = {
  open: boolean;
};

const Context = createContext<SidebarContextType>({ open: false });

export const useSidebarContext = (): SidebarContextType => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('No SideBar context found');
  }

  return ctx;
};

type SidebarContextProviderProps = SidebarContextType & {
  children?: ReactNode;
};

export const SidebarContextProvider: FC<SidebarContextProviderProps> = ({
  children,
  ...contextValue
}) => {
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
