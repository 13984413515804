import { FC } from 'react';

export const Notifications: FC = () => {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 24.0903C11.7201 24.0903 12.8003 23.0102 12.8003 21.69H7.9997C7.9997 23.0102 9.07983 24.0903 10.4 24.0903ZM17.6009 16.8895V10.8887C17.6009 7.20429 15.6446 4.11991 12.2002 3.30382V2.48772C12.2002 1.4916 11.3961 0.6875 10.4 0.6875C9.40387 0.6875 8.59977 1.4916 8.59977 2.48772V3.30382C5.16736 4.11991 3.19912 7.19228 3.19912 10.8887V16.8895L0.798828 19.2897V20.4899H20.0011V19.2897L17.6009 16.8895ZM15.2006 18.0896H5.59941V10.8887C5.59941 7.91237 7.41163 5.48808 10.4 5.48808C13.3883 5.48808 15.2006 7.91237 15.2006 10.8887V18.0896Z"
        fill="#376E8D"
      />
      <path
        d="M10.4016 24.0775C11.7217 24.0775 12.8019 22.9974 12.8019 21.6772H8.0013C8.0013 22.9974 9.08143 24.0775 10.4016 24.0775ZM17.6025 16.8767V10.8759C17.6025 7.19149 15.6462 4.10712 12.2018 3.29102V2.47492C12.2018 1.4788 11.3977 0.674706 10.4016 0.674706C9.40547 0.674706 8.60137 1.4788 8.60137 2.47492V3.29102C5.16896 4.10712 3.20072 7.17949 3.20072 10.8759V16.8767L0.800432 19.277V20.4771H20.0027V19.277L17.6025 16.8767ZM15.2022 18.0768H5.60101V10.8759C5.60101 7.89958 7.41323 5.47529 10.4016 5.47529C13.39 5.47529 15.2022 7.89958 15.2022 10.8759V18.0768Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19938 2.47503C8.19938 1.25724 9.18318 0.273438 10.401 0.273438C11.6188 0.273438 12.6026 1.25724 12.6026 2.47503V2.98049C16.0752 3.95651 18.0032 7.16073 18.0032 10.876V16.7105L20.4035 19.1108V20.8786H0.398438V19.1108L2.79873 16.7105V10.876C2.79873 7.14824 4.73858 3.95622 8.19938 2.98073V2.47503ZM10.401 1.07618C9.62652 1.07618 9.00212 1.70058 9.00212 2.47503V3.60825L8.69359 3.68161C5.4826 4.44507 3.60147 7.32031 3.60147 10.876V17.043L1.20118 19.4433V20.0758H19.6008V19.4433L17.2005 17.043V10.876C17.2005 7.33118 15.3305 4.44504 12.1086 3.68168L11.7998 3.60851V2.47503C11.7998 1.70058 11.1754 1.07618 10.401 1.07618ZM7.18661 7.31865C6.43087 8.21291 6.00176 9.46661 6.00176 10.876V17.6755H14.8002V10.876C14.8002 9.46661 14.3711 8.21291 13.6153 7.31865C12.8651 6.43087 11.7771 5.87676 10.401 5.87676C9.02482 5.87676 7.93687 6.43087 7.18661 7.31865ZM6.57348 6.80051C7.47642 5.73206 8.78876 5.07402 10.401 5.07402C12.0132 5.07402 13.3255 5.73206 14.2285 6.80051C15.1259 7.86248 15.6029 9.30911 15.6029 10.876V18.4783H5.19902V10.876C5.19902 9.30911 5.67601 7.86248 6.57348 6.80051ZM7.59931 21.276H13.2026V21.6773C13.2026 23.2192 11.9428 24.479 10.401 24.479C8.85914 24.479 7.59931 23.2192 7.59931 21.6773V21.276ZM8.44291 22.0787C8.63031 22.9869 9.43983 23.6763 10.401 23.6763C11.3621 23.6763 12.1716 22.9869 12.359 22.0787H8.44291Z"
        fill="#EDEDED"
      />
    </svg>
  );
};
