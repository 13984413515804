import { FC } from 'react';

export const Profile: FC = () => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0033 9.73939C12.1473 9.73939 13.8854 8.00129 13.8854 5.85722C13.8854 3.71316 12.1473 1.97505 10.0033 1.97505C7.8592 1.97505 6.12109 3.71316 6.12109 5.85722C6.12109 8.00129 7.8592 9.73939 10.0033 9.73939ZM10.0033 11.0269C12.8584 11.0269 15.173 8.71238 15.173 5.85722C15.173 3.00206 12.8584 0.6875 10.0033 0.6875C7.1481 0.6875 4.83354 3.00206 4.83354 5.85722C4.83354 8.71238 7.1481 11.0269 10.0033 11.0269Z"
        fill="#376E8D"
      />
      <path
        d="M19.6862 19.5286C19.6862 23.2132 15.351 22.5517 10.0032 22.5517C4.6555 22.5517 0.320312 23.2132 0.320312 19.5286C0.320312 15.8439 4.6555 12.8569 10.0032 12.8569C15.351 12.8569 19.6862 15.8439 19.6862 19.5286Z"
        fill="#EDEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0479 20.613C18.2208 20.4521 18.3986 20.1759 18.3986 19.5286C18.3986 18.231 17.6365 16.9164 16.1196 15.8712C14.6081 14.8298 12.4496 14.1444 10.0032 14.1444C7.55683 14.1444 5.39832 14.8298 3.8869 15.8712C2.36999 16.9164 1.60787 18.231 1.60787 19.5286C1.60787 20.1759 1.7857 20.4521 1.95856 20.613C2.18019 20.8193 2.59804 21.0221 3.37028 21.1522C4.13089 21.2804 5.08086 21.3144 6.22927 21.31C6.74261 21.308 7.27971 21.2986 7.84614 21.2888C7.90694 21.2877 7.96807 21.2867 8.02954 21.2856C8.66011 21.2747 9.32258 21.2642 10.0032 21.2642C10.6839 21.2642 11.3464 21.2747 11.9769 21.2856C12.0384 21.2867 12.0995 21.2877 12.1603 21.2888C12.7268 21.2986 13.2639 21.308 13.7772 21.31C14.9256 21.3144 15.8756 21.2804 16.6362 21.1522C17.4084 21.0221 17.8263 20.8193 18.0479 20.613ZM12.1387 22.5762C16.459 22.6514 19.6862 22.7077 19.6862 19.5286C19.6862 15.8439 15.351 12.8569 10.0032 12.8569C4.6555 12.8569 0.320312 15.8439 0.320312 19.5286C0.320312 22.7077 3.5475 22.6514 7.8678 22.5762C8.55483 22.5642 9.2695 22.5517 10.0032 22.5517C10.737 22.5517 11.4516 22.5642 12.1387 22.5762Z"
        fill="#376E8D"
      />
    </svg>
  );
};
