import { useEffect, useMemo } from 'react';
import { usePostMessageContext } from '../PostMessageContext';
import { MessageListener, MessageType } from '../types';

export const useShellMessageListener = (
  id: string,
  type: MessageType,
  listener: MessageListener,
) => {
  const memoizedListener = useMemo(() => listener, [listener]);
  const { addListener, removeListener } = usePostMessageContext();

  useEffect(() => {
    addListener(id, type, memoizedListener);

    return () => {
      removeListener(id, type);
    };
  }, [id, type, memoizedListener, addListener, removeListener]);
};
