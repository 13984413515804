import { FC } from 'react';

export const MyFleet: FC = () => {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 42 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.32443 0.78125H31.0603V17.0007H4.32443V0.78125Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4677 2.3738H5.91698V15.4082H29.4677V2.3738ZM4.32443 0.78125V17.0007H31.0603V0.78125H4.32443Z"
        fill="white"
      />
      <path
        d="M4.32444 21.3629H31.0603V15.9035H4.32444V21.3629Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4677 19.7704H5.91699V17.496H29.4677V19.7704ZM4.32444 21.3629V15.9035H31.0603V21.3629H4.32444Z"
        fill="white"
      />
      <path
        d="M29.8471 9.96551H41.2444V21.3628H29.8471V9.96551Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6518 11.5581H31.4396V19.7702H39.6518V11.5581ZM29.8471 9.96551V21.3628H41.2444V9.96551H29.8471Z"
        fill="white"
      />
      <path
        d="M39.7222 22.522C39.7222 24.8286 37.8523 26.6985 35.5456 26.6985C33.239 26.6985 31.3691 24.8286 31.3691 22.522C31.3691 20.2154 33.239 18.3455 35.5456 18.3455C37.8523 18.3455 39.7222 20.2154 39.7222 22.522Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5456 25.106C36.9727 25.106 38.1296 23.9491 38.1296 22.522C38.1296 21.0949 36.9727 19.938 35.5456 19.938C34.1185 19.938 32.9617 21.0949 32.9617 22.522C32.9617 23.9491 34.1185 25.106 35.5456 25.106ZM35.5456 26.6985C37.8523 26.6985 39.7222 24.8286 39.7222 22.522C39.7222 20.2154 37.8523 18.3455 35.5456 18.3455C33.239 18.3455 31.3691 20.2154 31.3691 22.522C31.3691 24.8286 33.239 26.6985 35.5456 26.6985Z"
        fill="white"
      />
      <path
        d="M21.1912 17.2091C21.1912 23.0107 16.488 27.7139 10.6864 27.7139C4.88479 27.7139 0.181641 23.0107 0.181641 17.2091C0.181641 11.4075 4.88479 6.70433 10.6864 6.70433C16.488 6.70433 21.1912 11.4075 21.1912 17.2091Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6864 26.1213C15.6085 26.1213 19.5986 22.1312 19.5986 17.2091C19.5986 12.287 15.6085 8.29688 10.6864 8.29688C5.76432 8.29688 1.77419 12.287 1.77419 17.2091C1.77419 22.1312 5.76432 26.1213 10.6864 26.1213ZM10.6864 27.7139C16.488 27.7139 21.1912 23.0107 21.1912 17.2091C21.1912 11.4075 16.488 6.70433 10.6864 6.70433C4.88479 6.70433 0.181641 11.4075 0.181641 17.2091C0.181641 23.0107 4.88479 27.7139 10.6864 27.7139Z"
        fill="white"
      />
      <path
        d="M13.3938 14.1795C13.3938 15.6746 12.1817 16.8867 10.6866 16.8867C9.19147 16.8867 7.97942 15.6746 7.97942 14.1795C7.97942 12.6844 9.19147 11.4723 10.6866 11.4723C12.1817 11.4723 13.3938 12.6844 13.3938 14.1795Z"
        fill="white"
      />
      <path
        d="M15.7572 21.3387C15.7572 23.2682 13.487 22.9218 10.6866 22.9218C7.88621 22.9218 5.61603 23.2682 5.61603 21.3387C5.61603 19.4092 7.88621 17.845 10.6866 17.845C13.487 17.845 15.7572 19.4092 15.7572 21.3387Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9439 16.1427L37.9439 11.9042H39.5364V16.1427H37.9439Z"
        fill="white"
      />
    </svg>
  );
};
