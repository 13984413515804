import { AppsConfiguration } from '@/lib/config/apps';
import Config from '@/lib/config';
import { useMemo } from 'react';

type TargetApp = keyof AppsConfiguration;

type Params = Record<string, string | null | undefined>;

type BuildAppUrlType = (
  target: TargetApp,
  route: string,
  params?: Params,
) => string;

export const buildAppUrl: BuildAppUrlType = (target, route, params) => {
  const { baseUrl } = Config.apps[target];
  let path = route;

  if (params) {
    path = path.replace(/\{(\w+)}/g, (match, key) => {
      return params?.[key] || match;
    });
  }

  if (path === '/') {
    return baseUrl;
  }

  return `${baseUrl}/${path}`;
};

export const useBuildAppUrl: BuildAppUrlType = (target, route, params) =>
  useMemo(() => buildAppUrl(target, route, params), [target, route, params]);
