import { FC, useEffect, useState } from 'react';
import { SidebarItemProps } from './types';
import { Label } from './Label';
import { Drop, DropExtendedProps } from 'grommet';
import { useSidebarContext } from '../context';

const dropAlignment: DropExtendedProps['align'] = {
  top: 'top',
  left: 'right',
};

type DropSidebarItemProps = Omit<SidebarItemProps, 'href'>;

export const DropSidebarItem: FC<DropSidebarItemProps> = ({
  icon,
  label,
  children,
}) => {
  const { open } = useSidebarContext();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);

  const close = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (open) {
      return;
    }

    // Close drop when the sidebar closes
    setVisible(false);
  }, [open]);

  return (
    <>
      <Label
        icon={icon}
        ref={(e) => {
          setRef(e);
        }}
        boxProps={{
          onClick: () => {
            setVisible(true);
          },
        }}
      >
        {label}
      </Label>
      {visible && open ? (
        <Drop
          target={ref as object}
          align={dropAlignment}
          onClickOutside={close}
          onMouseLeave={close}
          onEsc={close}
          restrictFocus
          height="100%"
          inline
        >
          {children}
        </Drop>
      ) : null}
    </>
  );
};
