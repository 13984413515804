import { FC } from 'react';

export const Tasks: FC = () => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.51698 0.468753L19.187 0.46875C19.3191 0.46875 19.4459 0.521251 19.5393 0.614702C19.6328 0.708154 19.6853 0.834902 19.6853 0.967062V21.8041C19.6853 22.2428 19.3297 22.5984 18.891 22.5984H1.51698C1.07829 22.5984 0.722656 22.2428 0.722656 21.8041V1.26308C0.722656 0.824384 1.07829 0.468753 1.51698 0.468753ZM1.71928 1.46538V21.6018H18.6887V1.46537L1.71928 1.46538Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55137 2.36406C6.73771 2.56659 6.72457 2.88183 6.52203 3.06816L4.07033 5.32372C3.89149 5.48825 3.62004 5.49978 3.42789 5.35101L2.66789 4.76261C2.45027 4.59413 2.41044 4.28114 2.57892 4.06353C2.7474 3.84591 3.06039 3.80608 3.278 3.97456L3.70526 4.30535L5.84727 2.33472C6.0498 2.14838 6.36504 2.16152 6.55137 2.36406Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55137 6.64531C6.73771 6.84784 6.72457 7.16308 6.52203 7.34941L4.07033 9.60497C3.89149 9.7695 3.62004 9.78103 3.42789 9.63226L2.66789 9.04386C2.45027 8.87538 2.41044 8.56239 2.57892 8.34478C2.7474 8.12716 3.06039 8.08733 3.278 8.25581L3.70526 8.5866L5.84727 6.61597C6.0498 6.42963 6.36504 6.44277 6.55137 6.64531Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73437 4.65456C5.73437 4.37935 5.95748 4.15625 6.23269 4.15625L17.1703 4.15625C17.4455 4.15625 17.6686 4.37935 17.6686 4.65456C17.6686 4.92977 17.4455 5.15288 17.1703 5.15288L6.23269 5.15287C5.95748 5.15287 5.73437 4.92977 5.73437 4.65456Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73828 13.8264C2.73828 13.5512 2.96138 13.3281 3.23659 13.3281H17.1703C17.4455 13.3281 17.6686 13.5512 17.6686 13.8264C17.6686 14.1016 17.4455 14.3247 17.1703 14.3247H3.23659C2.96138 14.3247 2.73828 14.1016 2.73828 13.8264Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73438 9.2405C5.73438 8.96529 5.95748 8.74219 6.23269 8.74219L17.1703 8.74219C17.4455 8.74219 17.6686 8.96529 17.6686 9.2405C17.6686 9.51571 17.4455 9.73881 17.1703 9.73881L6.23269 9.73881C5.95748 9.73881 5.73438 9.51571 5.73438 9.2405Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73828 18.4124C2.73828 18.1372 2.96138 17.9141 3.23659 17.9141H17.1703C17.4455 17.9141 17.6686 18.1372 17.6686 18.4124C17.6686 18.6876 17.4455 18.9107 17.1703 18.9107H3.23659C2.96138 18.9107 2.73828 18.6876 2.73828 18.4124Z"
        fill="#376E8D"
      />
    </svg>
  );
};
