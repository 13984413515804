import { ReactNode } from 'react';
import { Box, Image, Text } from 'grommet';
import styled from 'styled-components';

export const StyledAppsTab = styled(Box)`
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 150px;
  min-height: 24px;
  background: #ededed;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  clip-path: inset(0px -10px -10px -10px);
  border-radius: 0 0 10px 10px;
  text-transform: uppercase;
`;

export const StyledAppsTabLabel = styled(Text)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;

  :after {
    display: block;
    content: ' ';
    width: 24px;
    height: 2px;
    background-color: ${({ theme }) => theme.global.colors.secondaryText};
    border-radius: 2px;
  }
`;

export const StyledTopBarItem = styled(Box)`
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 100%;
`;

export const StyledTopBar = styled(Box)`
  position: relative;
  background-color: #ededed;
`;

export const StyledLogo = styled(Image)`
  padding: 0 35px;
`;

export const StyledSidebarItemLabel = styled(Text)`
  color: white;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  margin-left: 8px;
  text-transform: uppercase;
  min-width: 68px;
  opacity: 1;

  transition: opacity ease-in-out 140ms;
`;

export const StyledSidebarItem = styled(Box)`
  padding: 21px 17px;
`;

type StyledLinkProps = {
  href: string;
  children: ReactNode;
};

export const StyledLink = styled.a<StyledLinkProps>`
  text-decoration: none;

  &[data-active='true'] ${StyledSidebarItem} {
    background-color: rgba(255, 255, 255, 0.25);
  }
`;

export const StyledSidebar = styled(Box)`
  background-color: #376e8d;
  transition: width ease-in-out 200ms;
  width: 70px;

  ${StyledSidebarItemLabel} {
    opacity: 0;
  }

  :focus,
  :hover {
    width: 145px;

    ${StyledSidebarItemLabel} {
      opacity: 1;
    }
  }
`;
