import { FC } from 'react';

export const Reports: FC = () => {
  return (
    <svg
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.146484 1.87592C0.146484 1.36196 0.563129 0.945312 1.07709 0.945312H24.9258C25.4397 0.945312 25.8564 1.36196 25.8564 1.87591V31.6092C25.8564 32.1231 25.4397 32.5398 24.9258 32.5398H1.07709C0.563129 32.5398 0.146484 32.1231 0.146484 31.6092V1.87592Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00769 2.80652V30.6786H23.9952V2.80652H2.00769ZM1.07709 0.945312C0.563129 0.945312 0.146484 1.36196 0.146484 1.87592V31.6092C0.146484 32.1232 0.563129 32.5398 1.07709 32.5398H24.9258C25.4397 32.5398 25.8564 32.1232 25.8564 31.6092V1.87591C25.8564 1.36196 25.4397 0.945312 24.9258 0.945312H1.07709Z"
        fill="white"
      />
      <path
        d="M5.85547 5.40717C5.85547 4.89321 6.27211 4.47656 6.78607 4.47656H30.6347C31.1487 4.47656 31.5653 4.89321 31.5653 5.40716V35.1404C31.5653 35.6544 31.1487 36.071 30.6347 36.071H6.78607C6.27211 36.071 5.85547 35.6544 5.85547 35.1404V5.40717Z"
        fill="#376E8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71667 6.33777V34.2098H29.7041V6.33777H7.71667ZM6.78607 4.47656C6.27211 4.47656 5.85547 4.89321 5.85547 5.40717V35.1404C5.85547 35.6544 6.27211 36.071 6.78607 36.071H30.6347C31.1487 36.071 31.5653 35.6544 31.5653 35.1404V5.40716C31.5653 4.89321 31.1487 4.47656 30.6347 4.47656H6.78607Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42969 10.5669C9.42969 10.3099 9.63801 10.1016 9.89499 10.1016H27.5013C27.7583 10.1016 27.9666 10.3099 27.9666 10.5669C27.9666 10.8238 27.7583 11.0322 27.5013 11.0322H9.89499C9.63801 11.0322 9.42969 10.8238 9.42969 10.5669Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42969 22.9262C9.42969 22.6693 9.63801 22.4609 9.89499 22.4609H27.5013C27.7583 22.4609 27.9666 22.6693 27.9666 22.9262C27.9666 23.1832 27.7583 23.3915 27.5013 23.3915H9.89499C9.63801 23.3915 9.42969 23.1832 9.42969 22.9262Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42969 16.7466C9.42969 16.4896 9.63801 16.2812 9.89499 16.2812H27.5013C27.7583 16.2812 27.9666 16.4896 27.9666 16.7466C27.9666 17.0035 27.7583 17.2119 27.5013 17.2119H9.89499C9.63801 17.2119 9.42969 17.0035 9.42969 16.7466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42969 29.1059C9.42969 28.8489 9.63801 28.6406 9.89499 28.6406H27.5013C27.7583 28.6406 27.9666 28.8489 27.9666 29.1059C27.9666 29.3629 27.7583 29.5712 27.5013 29.5712H9.89499C9.63801 29.5712 9.42969 29.3629 9.42969 29.1059Z"
        fill="white"
      />
    </svg>
  );
};
