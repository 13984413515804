import { AuthorizationParams } from '@auth0/auth0-react';

/**
 * Auth0 tenant domain
 */
const AUTH0_DOMAIN =
  process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'roadz-test.auth0.com';

/**
 * Audience of who will be using the token.
 * Set this to the backend's Auth0 API audience identifier.
 */
const AUTH0_AUDIENCE =
  process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || 'http://localhost:8000';

/**
 * Auth0 application id
 */
const AUTH0_CLIENT_ID =
  process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '5ZTEnAsSFeoN3nOS4zRoNYOfwyaxOTk3';

/**
 * Application deployment domain (including protocol)
 */
const BASE_DOMAIN =
  process.env.NEXT_PUBLIC_APP_BASE_DOMAIN || 'http://localhost:8000';

type Auth0Config = {
  domain: string;
  clientId: string;
  authorizationParams?: AuthorizationParams;
};

export const auth0Config: Auth0Config = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: BASE_DOMAIN,
    audience: AUTH0_AUDIENCE,
    scope: 'openid profile email offline_access',
  },
};
