import type { AppProps } from 'next/app';
import { ApplicationWrapper } from '@/lib/components/app/ApplicationWrapper';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ApplicationWrapper>
      <Component {...pageProps} />
    </ApplicationWrapper>
  );
}
