import { FC, useRef, useState } from 'react';
import { Box, BoxProps, Button, Layer, Text } from 'grommet';
import { StyledSidebar } from '../Styled';
import {
  Apps,
  //   Dashboards,
  //   MyFleet,
  //   Reports,
  //   Settings,
} from '@/lib/components/icons';
import { SidebarItem } from './SidebarItem';
import { SidebarContextProvider } from './context';
import { usePostMessageContext } from '@/lib/contexts/PostMessageContext';
import { ExternalAppsMenu } from './ExternalAppsMenu';
import { appsConfig } from '@/lib/config/apps';
import { buildAppUrl } from '@/lib/utils';

export const Sidebar: FC<BoxProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const nextUrl = useRef<string>('');
  const { publish, viewMode } = usePostMessageContext();

  const sidebarClickHandler = (event: MouseEvent) => {
    let target = event.target;
    let href = '';
    let app = '';

    // Gets URL of clicked sidebar child
    while (target !== document) {
      if ((target as HTMLElement).tagName === 'A') {
        href = (target as HTMLElement).getAttribute('href') as string;
        app = (target as HTMLElement).getAttribute('data-app') as string;
        nextUrl.current = href;
        break;
      }

      target = (target as HTMLElement).parentNode;
    }

    const iframeURL = document?.querySelector('iframe')?.src;

    if (
      app === 'retool' ||
      // TODO: fix navigation
      window.location.href.endsWith('/tasks') ||
      window.location.href.includes('/apps') ||
      iframeURL?.includes('-ai-dev.teamroadz')
    ) {
      return;
    }

    event.preventDefault();

    if (viewMode.current === 'editing mode') {
      setShowPopUp(true);
    } else {
      publish(
        {
          type: 'navigation',
          payload: {
            route: href,
          },
        },
        '*',
      );
      window.history.pushState({}, '', href);
    }
  };

  const confirmClickHandler = () => {
    viewMode.current = 'normal';
    setShowPopUp(false);
    publish(
      {
        type: 'navigation',
        payload: {
          route: nextUrl.current,
        },
      },
      '*',
    );
    window.history.pushState({}, '', nextUrl.current);
  };

  return (
    <>
      <SidebarContextProvider open={open}>
        <StyledSidebar
          {...props}
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          onClick={sidebarClickHandler}
        >
          {/* <SidebarItem icon={<MyFleet />} label="My Fleet" href="/my-fleet" /> */}
          {/* <SidebarItem */}
          {/*   icon={<Dashboards />} */}
          {/*   label="Dashboards" */}
          {/*   href="/charts" */}
          {/* /> */}
          <SidebarItem icon={<Apps />} label="Apps">
            <ExternalAppsMenu />
          </SidebarItem>
          {/* <SidebarItem icon={<Reports />} label="Reports" href="/reports" /> */}
          {/* <SidebarItem icon={<Settings />} label="Settings" href="/settings" /> */}

          {Object.entries(appsConfig.retool.apps)
            .filter(([appId, _]) => appId !== 'undefined')
            .map(([appId, appConfig]) => {
              return (
                <SidebarItem
                  key={appId}
                  icon={<appConfig.icon color="white" size="33" />}
                  label={appConfig.name}
                  app="retool"
                  href={buildAppUrl('shell', 'apps/{id}', { id: appId })}
                />
              );
            })}
        </StyledSidebar>
      </SidebarContextProvider>
      {showPopUp && (
        <Layer
          onEsc={() => setShowPopUp(false)}
          onClickOutside={() => setShowPopUp(false)}
        >
          <Box pad="medium">
            <Text size="17px">Are you sure you want to leave?</Text>
            <Box direction="row" justify="between" pad={{ top: 'medium' }}>
              <Button label="confirm" onClick={confirmClickHandler} primary />
              <Button
                label="cancel"
                onClick={() => setShowPopUp(false)}
                primary
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
